import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Switch, NavLink, Route, useParams } from "react-router-dom";

import {API_URL} from './_var';


import TamadaLoginScreen from "./partTamadaLogin";
import TamadaTransactions from "./partTamadaTransactions";
import TamadaStats from "./partTamadaStats";


class TamadaSwitcher extends Component {

    constructor(props) {
        super(props);

        this.state = {
            auth: false,
        }
    }

    componentDidMount() {

        if ( Cookies.get('token') ) {
            axios.get(API_URL+"tamada/auth?token="+Cookies.get('token'))
            .then( result => {
                this.setState({
                    auth: true,
                });
            },
            error => {
            } );
        }
    }

    logOut = () => {
        Cookies.remove('token');

        window.location.reload(false);
    }


    render(){

        const { auth } = this.state;


        return(
            <div className="container wrapper">
                { !auth ? (
                    <TamadaLoginScreen />
                ) : (
                    <div>
                        <TamadaHeader page={this.props.location.pathname} logout={this.logOut} />

                        <Switch>
                          <Route exact path='/tamada' component={TamadaTransactions}></Route>
                          <Route exact path='/tamada/stats' component={TamadaStats}></Route>
                        </Switch>
                    </div>
                ) }
            </div>
        )
    }

}


function TamadaHeader(props){

    const { logout, page } = props;

    const getNavLinkClass = (path) => {
      return page === path ? 'active' : '';
    }


    const backupLink = API_URL+"tamada/backup?token="+Cookies.get('token')



    return(
        <ul className="nav nav-pills nav-justified">
            <li role="presentation" className={getNavLinkClass("/tamada")}><NavLink to="/tamada">ტრანზაქციები</NavLink></li>
            <li role="presentation" className={getNavLinkClass("/tamada/stats")}><NavLink to="/tamada/stats">სტატისტიკა</NavLink></li>
            <li role="presentation"><a href={backupLink} target="_blank">ბაცქუპი</a></li>
            <li role="presentation"><button className="glyphicon glyphicon-log-out" onClick={logout} title="გამოსვლა"></button></li>
        </ul>
    )
}



export default TamadaSwitcher;
