import React, { Component } from 'react';

import { Switch, Route, useParams } from "react-router-dom";


import Navigation from './pages/_navigation';
import BodySwitcher from './pages/_bodyswitcher';

import TamadaSwitcher from './pages/partTamadaSwitcher';


import './media/index.css';

class App extends Component {



    render(){

        return(
            <div>
                <Switch>
                  <Route path='/tamada' component={TamadaSwitcher}></Route>
                  <Route>
                      <div className="container wrapper">
                          <Navigation />
                          <BodySwitcher />
                      </div>
                  </Route>
                </Switch>
            </div>
        )
    }

}


export default App;
