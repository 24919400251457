import React, { Component } from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import Cookies from 'js-cookie';

import {API_URL} from './_var';


const loadOptions = async (inputValue, callback) => {
    // perform a request
    if ( inputValue.length > 3 ) {
        let res = await axios.get(API_URL+"tamada/findmember?q="+inputValue,{
            headers: {
                'Authorization': 'Bearer '+Cookies.get('token')
            }
        });

        if ( res.data.length && res.data[0].label ) {
            callback(res.data);
        }
    }


};

class TamadaTransactions extends Component {


    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            lastTransactions: {},
            formData: {
                member: '',
                amount: '',
                description: ''
            },

        }
    }

    componentDidMount() {

        this.setState({
            ...this.state,
            isLoaded: false,
        });

        axios.get(API_URL+"tamada/lasttransactions",{
            headers: {
                'Authorization': 'Bearer '+Cookies.get('token')
            }
        })
        .then( result => {

            this.setState({
                ...this.state,
                lastTransactions: result.data,
                isLoaded: true,
            });

        },
        error => {

        } );

        //console.log(res);
    }


    handleInputChange = (selectTyping) => {
        this.setState({
            formData: {
                ...this.state.formData,
                member: selectTyping
            },
        });
        //return selectTyping;
    };

    handleInputChange2 =(e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            },
        });
    }

    sendTransaction() {

        axios.post(API_URL+"tamada/addtransaction", this.state.formData, {
            headers: {
                'Authorization': 'Bearer '+Cookies.get('token')
            }
        })
            .then( result => {

                this.setState({
                    ...this.state,
                    lastTransactions: result.data,
                    formData: {
                        member: '',
                        amount: '',
                        description: ''
                    }
                });

            },
            error => {
                this.setState({
                    isLoaded: true,
                    error
                })
            } )


    }

    render(){

        const { error, isLoaded, formData, lastTransactions } = this.state;


        let transItems = [];

        if ( isLoaded ) {
            transItems = lastTransactions.map( trans => {
                return (
                    <tr key={trans._id }>
                        <td>{trans.member.fullname }</td>
                        <td>{trans.member.email }</td>
                        <td>{trans.member.private_id }</td>
                        <td>{trans.amount/100 }</td>
                        <td>{trans.description }</td>
                        <td>{trans.createdAt }</td>
                        <td>{trans.member.deposit/100 }</td>
                    </tr>
                )
            } );
        }


        return(
            <div>

                <div className="jumbotron">

                        <div className="row">
                          <div className="col-lg-4">
                              <AsyncSelect
                                loadOptions={loadOptions}
                                onChange={this.handleInputChange}
                                defaultOptions
                                value={formData.member}
                              />
                          </div>
                          <div className="col-lg-2">
                              <input type="text" className="form-control" id="amount" placeholder="თანხა ლარებში" autoComplete="off" name="amount" onChange={ this.handleInputChange2.bind(this)} value={formData.amount} />
                          </div>
                          <div className="col-lg-5">
                              <input type="text" className="form-control" id="description" placeholder="დეტალური აღწერა (საბანკო გადმორიცხვა #NUMBER)" name="description" onChange={ this.handleInputChange2.bind(this)} value={formData.description} />
                          </div>
                          <div className="col-lg-1">
                              <button type="button" id="trans_add" className="btn btn-default glyphicon glyphicon-ok" onClick={ ()=>{ this.sendTransaction() } }></button>
                          </div>
                        </div>

                </div>


                <table className="table table-hover">
                    <caption>ბოლო 10 ტრანზაქცია</caption>
                    <thead>
                        <tr>
                            <th>კლუბელის სახელი</th>
                            <th>ელ. ფოსტა</th>
                            <th>პირადი ნომერი</th>
                            <th>თნხა</th>
                            <th>ტრანზაქციის აღწერა</th>
                            <th>ასახვის თარიღი</th>
                            <th>ნაშთი</th>
                        </tr>
                    </thead>
                    <tbody>
                        { transItems }
                    </tbody>
                </table>

            </div>
        )
    }

}



export default TamadaTransactions;
