import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router";


class Navigation extends React.Component {
 getNavLinkClass = (path) => {
   return this.props.location.pathname === path ? 'active' : '';
 }
 render() {
  return (
    <nav>
      <ul className="nav nav-pills nav-justified">
        <li className={this.getNavLinkClass("/")}><NavLink exact to="/">კლუბის წევრობა</NavLink></li>
        <li className={this.getNavLinkClass("/check")}><NavLink to="/check">ანგარიშის შემოწმება</NavLink></li>
        <li className={this.getNavLinkClass("/register")}><NavLink to="/register">წევრად რეგისტრაცია</NavLink></li>
        {/* <li><a href="http://wineclub.ge" className="glyphicon glyphicon-log-out" title="საიტზე გადასვლა"></a></li> */}
      </ul>
    </nav>
  )};
}
Navigation = withRouter(Navigation);

export default Navigation;
