import React from 'react';
import { Switch, Route } from 'react-router-dom';


import Home from './partHome';
import Check from './partCheck';
import Register from './partRegister';
import Verify from './partVerify';


const BodySwitcher = () => (
  <Switch>
    <Route exact path='/' component={Home}></Route>
    <Route exact path='/check' component={Check}></Route>
    <Route exact path='/register' component={Register}></Route>
    <Route exact path='/verify/:result' component={Verify}></Route>
  </Switch>
);


export default BodySwitcher;
