import React, { Component } from 'react';
import axios from 'axios';

import Cookies from 'js-cookie';

import {API_URL} from './_var';



class TamadaStats extends Component {


    constructor(props) {
        super(props);

        this.state = {
            error: null,
            stats: {},
            isLoaded: false,
        }
    }

    componentDidMount() {

        this.setState({
            ...this.state,
            isLoaded: false,
        });

        axios.get(API_URL+"tamada/stats", {
            headers: {
                'Authorization': 'Bearer '+Cookies.get('token')
            }
        })
        .then( result => {

            this.setState({
                ...this.state,
                stats: result.data,
                isLoaded: true,
            });

        },
        error => {

        } );

        //console.log(res);
    }



    render(){

        const { error, isLoaded, stats } = this.state;


        let members = [];

        if ( isLoaded && stats.members ) {
            members = stats.members.map( item => {
                return (
                    <tr key={ item._id }>
                        <td>{ item._id }</td>
                        <td>{ item.fullname }</td>
                        <td>{ item.email }</td>
                        <td>{ item.phone }</td>
                        <td>{ item.deposit/100 }</td>
                        <td>{ item.status ? 'Active' : 'Inactive' }</td>
                    </tr>
                )
            } );
        }


        return(
            <div>

                <div className="jumbotron">

                        <div className="row">
                          <div className="col-lg-6">
                              აქტიური წევრი: <strong>{ stats.activeMembers }</strong>
                          </div>
                          <div className="col-lg-6">
                              შემოსული თანხა: <strong>{ stats.income/100 }</strong>
                          </div>
                        </div>

                </div>


                <table className="table table-hover">
                    <caption>რეგისტრირებული წევრები:</caption>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>სახელი</th>
                            <th>ელ. ფოსტა</th>
                            <th>ტელეფონი</th>
                            <th>ნაშთი</th>
                            <th>სტატუსი</th>
                        </tr>
                    </thead>
                    <tbody>
                        { members }
                    </tbody>
                </table>

            </div>
        )
    }

}



export default TamadaStats;
