import React, { Component } from 'react';

import { useParams } from "react-router-dom";



class Verify extends Component {

    render(){


        const result = {
            ok : 'ანგარიში გააქტიურდა',
            fail: 'ლინკი არასწორია',
        };



        return(
            <div className="jumbotron">
              <h1>{result[this.props.match.params.result]}</h1>
            </div>
        )
    }

}



export default Verify;
