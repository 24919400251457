import React, { Component } from 'react';
import axios from 'axios';

import {API_URL} from './_var';


class Register extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            isLoading: false,
            member: {},
            formData: {},
        }
    }

    handleInputChange(e){
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            },
        });
    }

    registerMe() {
        this.setState({
            isLoading: true
        });

        axios.post(API_URL+"members/add", this.state.formData)
            //.then( res => res.json() )
            .then( result => {

                this.setState({
                    isLoaded: true,
                    isLoading: false,
                    member: result.data,
                    error: null,
                });

            },
            error => {
                this.setState({
                    isLoaded: true,
                    isLoading: false,
                    error: error.response.data
                })
            } )
    }

    // render here
    render(){

        const { error, isLoaded, member, formData, isLoading } = this.state;

        return (
            <div className="jumbotron">
                <h1>ღვინის კლუბის წევრად რეგისტრაცია</h1>
                <div className="alert alert-info" role="alert"><strong>შეგახსენებთ!</strong> კლუბის საწევრო შემოწირულობა შეადგენს თვეში 10 ლარს.</div>

                <div id="form_area">
                    {
                        ( member.data && isLoaded ) ? this.DisplaySuccess(member) :
                        this.DisplayForm(error, member, isLoading, formData, this.handleInputChange)
                    }
                </div>
            </div>
        );

        // if ( member && isLoaded ) {
        //     return this.DisplaySuccess(member);
        // } else {
        //     return this.DisplayForm(error, member, isLoading, formData, this.handleInputChange);
        // }


    }


    DisplayForm = (error, member, isLoading, formData, handleInputChange) => {

        return (<div>

                    <div className={`form-group ${(error && error.error_fields && error.error_fields.includes("fullname")) ? 'has-error' : '' }`}>
                      <label htmlFor="fullname">სახელი და გვარი</label>
                      <input type="text" className="form-control" id="fullname" name="fullname" placeholder="თქვენი სახელი და გვარი ქართული სიმბოლოებით" onChange={ handleInputChange.bind(this)} />
                    </div>

                    <div className={`form-group ${(error && error.error_fields && error.error_fields.includes("phone")) ? 'has-error' : '' }`}>
                      <label htmlFor="phone">ტელეფონის ნომერი</label>
                      <input type="text" className="form-control" id="phone" name="phone" placeholder="5XX XX XX XX" onChange={ handleInputChange.bind(this)} />
                    </div>

                    <div className={`form-group ${(error && error.error_fields && error.error_fields.includes("email")) ? 'has-error' : '' }`}>
                      <label htmlFor="email">ელ.ფოსტის მისამართი</label>
                      <input type="email" className="form-control" name="email" id="email" placeholder="youremail@gmail.com" onChange={ handleInputChange.bind(this)} />
                    </div>

                    <div className={`form-group ${(error && error.error_fields && error.error_fields.includes("private_id")) ? 'has-error' : '' }`}>
                      <label htmlFor="private_id">პირადი ნომერი</label>
                      <input type="text" className="form-control" name="private_id" id="private_id" placeholder="11 ნიშნა პირადი ნომერი პირადობის მოწმობიდან" onChange={ handleInputChange.bind(this)} />
                    </div>

                    {/*

                    <div className="form-group" id="area_data_birthdate">
                      <label htmlFor="data_birthdate">დაბადების თარიღი</label>
                      <input type="text" className="form-control" id="data_birthdate" placeholder="რიცხვი-თვე-წელი" />
                    </div>

                    <div className="form-group" id="area_data_address">
                      <label htmlFor="data_address">საცხოვრებელი მისამართი</label>
                      <input type="text" className="form-control" id="data_address" placeholder="" />
                    </div>

                    <div className="form-group" id="area_data_workplace">
                      <label htmlFor="data_workplace">სამუშაო ადგილი</label>
                      <input type="text" className="form-control" id="data_workplace" placeholder="" />
                    </div>


                    <div className="form-group" id="area_data_education">
                      <label htmlFor="data_education">განათლება</label>
                      <textarea className="form-control" id="data_education" rows="3"></textarea>
                    </div>


                    <div className="form-group" id="area_data_wineeducation">
                      <label htmlFor="data_wineeducation">მიღებული გაქვთ თუ არა სპეციალური განათლება მევენახეობა/მეღვინეობის დარგში? (თუ კი, აღწერეთ დეტალურად)</label>
                      <textarea className="form-control" id="data_wineeducation" rows="3"></textarea>
                    </div>


                    <div className="form-group" id="area_data_ifwantwinetranings">
                      <label htmlFor="data_ifwantwinetranings">გინდათ თუ არა გააღრმავოთ საკუთარი პრეფესიული დონე და გაიაროთ დამატებითი სწავლება მევენახეობა მეღვინეობის დარგში?</label>
                      <select id="data_ifwantwinetranings" className="form-control">
                          <option value=""></option>
                          <option value="კი">კი</option>
                          <option value="არა">არა</option>
                      </select>
                    </div>


                    <div className="form-group" id="area_data_persontype">
                        <label htmlFor="data_persontype">ჩამოთვლილთაგან, რომელ კატეგორიას მიაკუთვნებდით საკუთარ თავს?</label>
                        <div className="checkbox">
                              <label>
                                  <input type="checkbox" name="data_persontype" value="სომელიე" />
                                   სომელიე
                              </label>

                            <label>
                                <input type="checkbox" name="data_persontype" value="ენოლოგი" />
                                 ენოლოგი
                            </label>

                            <label>
                                <input type="checkbox" name="data_persontype" value="მეცნიერი" />
                                 მეცნიერი
                            </label>

                            <label>
                                <input type="checkbox" name="data_persontype" value="ჟურნალისტი" />
                                 ჟურნალისტი
                            </label>

                            <label>
                                <input type="checkbox" name="data_persontype" value="ღვინის კომპანიის წარმომადგენელი" />
                                 ღვინის კომპანიის წარმომადგენელი
                            </label>

                            <label>
                                <input type="checkbox" name="data_persontype" value="ტურიზმის ინდუსტრიის წარმომადგენელი" />
                                   ტურიზმის ინდუსტრიის წარმომადგენელი
                            </label>

                            <label>
                                <input type="checkbox" name="data_persontype" value="ღვინის მოყვარული" />
                                  ღვინის მოყვარული
                            </label>

                            <label>
                                <input type="checkbox" name="data_persontype" value="სხვა" />
                                  სხვა
                            </label>
                        </div>
                    </div>

                    <div className="form-group" id="area_data_whywantmembership">
                      <label htmlFor="data_whywantmembership">რატომ გსურთ კლუბის წევრობა? (რას მოგიტანთ პირადად თქვენ კლუბის წევრობა?)</label>
                      <textarea className="form-control" id="data_whywantmembership" rows="3"></textarea>
                    </div>


                    <div className="form-group" id="area_data_interestedclubevents">
                      <label htmlFor="data_interestedclubevents">
                          როგორი ღონისძიებები იქნებოდა თქვენთვის საინტერესო კლუბში?
                      </label>
                      <textarea id="data_interestedclubevents" className="form-control" rows="3"></textarea>
                    </div>


                    <div className="form-group" id="area_data_wantsector">
                      <label htmlFor="data_wantsector">თუ გსურთ და შეგიძლიათ კლუბის რომელიმე სექციის მუშაობაში მონაწილეობის მიღება? (თუ გსურთ, მონიშნეთ სასურველი)</label>
                      <div className="checkbox">
                            <label>
                              <input type="checkbox" name="data_wantsector" value="ინფორმაციისა და საზოგადოებასთან ურთიერთობის სექცია" />
                               ინფორმაციისა და საზოგადოებასთან ურთიერთობის სექცია
                            </label>

                            <label>
                                <input type="checkbox" name="data_wantsector" value="ბიზნესის სექცია" />
                                 ბიზნესის სექცია
                            </label>

                            <label>
                                <input type="checkbox" name="data_wantsector" value="იურიდიული სექცია" />
                                  იურიდიული სექცია
                            </label>

                            <label>
                                <input type="checkbox" name="data_wantsector" value="საქართველოს რეგიონებთან ურთიერთობის სექცია" />
                                 საქართველოს რეგიონებთან ურთიერთობის სექცია
                            </label>

                            <label>
                                <input type="checkbox" name="data_wantsector" value="საერთაშორისო სექცია" />
                                 საერთაშორისო სექცია
                            </label>

                            <label>
                                <input type="checkbox" name="data_wantsector" value="კულტურული მემკვიდრეობის სექცია" />
                                    კულტურული მემკვიდრეობის სექცია
                            </label>

                            <label>
                                <input type="checkbox" name="data_wantsector" value="საგანმანათლებლო სექცია" />
                                  საგანმანათლებლო სექცია
                            </label>

                            <label>
                                <input type="checkbox" name="data_wantsector" value="სხვა" />
                                  სხვა
                            </label>
                        </div>
                    </div>


                    <div className="form-group" id="area_data_wantenthusiasm">
                      <label htmlFor="data_wantenthusiasm">მიიღებდით თუ არა კლუბის მუშაობაში მონაწილეობას ანაზღაურების გარეშე?</label>
                      <div className="radio">
                        <label>
                          <input type="radio" name="data_wantenthusiasm" id="" value="დიახ" />
                          დიახ
                        </label>

                        <label>
                          <input type="radio" name="data_wantenthusiasm" id="" value="არა" />
                          არა
                        </label>

                        <label>
                          <input type="radio" name="data_wantenthusiasm" id="" value="სხვა" />
                          სხვა
                        </label>
                      </div>
                    </div>

                    <div className="form-group" id="area_data_georgianwinetastenums">
                        <label htmlFor="data_georgianwinetastenums">რამდენად ხშირად აჭაშნიკებთ ქართულ ღვინოებს? </label>
                        <div className="radio">
                          <label>
                            <input type="radio" name="data_georgianwinetastenums" value="ყოველდღე" />
                            ყოველდღე
                          </label>

                          <label>
                            <input type="radio" name="data_georgianwinetastenums" value="კვირაში ერთხელ" />
                             კვირაში ერთხელ
                          </label>

                          <label>
                            <input type="radio" name="data_georgianwinetastenums" value="თვეში ერთხელ" />
                             თვეში ერთხელ
                          </label>

                          <label>
                            <input type="radio" name="data_georgianwinetastenums" value="წელიწადში რამდენჯერმე" />
                             წელიწადში რამდენჯერმე
                          </label>

                          <label>
                            <input type="radio" name="data_georgianwinetastenums" value="სხვა" />
                            სხვა
                          </label>
                        </div>
                    </div>


                    <div className="form-group" id="area_data_importedwinestasting">
                      <label htmlFor="data_importedwinestasting">რამდენად ხშირად აჭაშნიკებთ უცხოურ ღვინოებს? </label>
                      <div className="radio">
                        <label>
                          <input type="radio" name="data_importedwinestasting" value="ყოველდღე" />
                          ყოველდღე
                        </label>

                        <label>
                          <input type="radio" name="data_importedwinestasting" value="კვირაში ერთხელ" />
                           კვირაში ერთხელ
                        </label>

                        <label>
                          <input type="radio" name="data_importedwinestasting" value="თვეში ერთხელ" />
                           თვეში ერთხელ
                        </label>

                        <label>
                          <input type="radio" name="data_importedwinestasting" value="წელიწადში რამდენჯერმე" />
                           წელიწადში რამდენჯერმე
                        </label>

                        <label>
                          <input type="radio" name="data_importedwinestasting" value="არ გამისინჯავს" />
                           არ გამისინჯავს
                        </label>

                        <label>
                          <input type="radio" name="data_importedwinestasting" value="სხვა" />
                           სხვა
                        </label>
                      </div>
                    </div>


                      <div className="form-group" id="area_data_knownmagazines">
                        <label htmlFor="data_knownmagazines">
                            რომელ ღვინის პრესას ან საიტს ადევნებთ თვალს?
                        </label>
                        <textarea className="form-control" id="data_knownmagazines" rows="3"></textarea>
                      </div>


                      <div className="form-group" id="area_data_comment">
                        <label htmlFor="data_comment">
                            სხვა კომენტარი
                        </label>
                        <textarea id="data_comment" className="form-control" rows="3"></textarea>
                      </div>

                      */}


                      <p>
                          <button className={`btn btn-primary btn-lg ${isLoading ? 'disabled' : '' }`} role="button" onClick={ ()=>{ this.registerMe() } } >რეგისტრაცია</button>
                      </p>
                      </div>
        );
    }


    DisplaySuccess = (member) => {

        return (
            <div className="alert alert-success" role="alert">
                <strong>გმადლობთ, ფორმა გაგზავნილია!</strong> <br />
                რეგისტრაციის დასასრულებლად და თქვენი ანგარიშის გასააქტიურებლად, გთხოვთ გაეცნოთ ფორმაში მითითებულ ელ.ფოსტაზე ({ member.data.email }) ჩვენ მიერ გამოგზავნილ წერილს.
                <br /><br />
                კლუბის ლექციებისა თუ სხვა ღონისძიებების შესახებ წერილი გამოგეგზავნებათ ამავე ელ. ფოსტაზე.
            </div>
        );

    }

}

export default Register;
