import React from 'react';


import Logo from './../media/wineclublogo.png';


const Home = () => (
    <div className="jumbotron">
      <h1>მოგესალმებით ღვინის კლუბში!</h1>
      <div className="text-center"><img src={Logo} alt="Wine Club" className="home-logo" /></div>
      <p>თქვენ იმყოფებით ღვინის კლუბის წევრების პორტალში.</p>
      <p>აქ თქვენ შეგილიათ <a href="/register">დარეგისტრირდეთ</a>, როგორც კლუბის წევრი, ან <a href="/check">შეამოწმოთ</a> თქვენი ანგარიში.</p>
    </div>
);

export default Home;
