import React, { Component } from 'react';
import axios from 'axios';

import {API_URL} from './_var';




class Check extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            member: {},
            formData: {},
        }
    }


    handleInputChange(e){
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            },
        });
    }


    checkMe() {

        axios.get(API_URL+"members/check", {
                params: this.state.formData
            })
            //.then( res => res.json() )
            .then( result => {

                this.setState({
                    isLoaded: true,
                    member: result.data
                });

            },
            error => {
                this.setState({
                    isLoaded: true,
                    error
                })
            } )


    }







    render(){
        // some code here

        const { error, isLoaded, member, formData } = this.state;

        return (
            <div className="jumbotron">
              <h1>კლუბის წევრის ანგარიშის შემოწმება</h1>
              <p>სისტემა განკუთვნილია მხოლოდ პირადი ანგარიშის შესამოწმებლად.</p>


              <div className="form-group">
                <label htmlFor="email">ელ. ფოსტა</label>
                <input type="email" className="form-control" name="email" id="email" placeholder="Email" onChange={ this.handleInputChange.bind(this)} value={formData.email} />
              </div>
              <div className="form-group">
                <label htmlFor="private_id">პირადი ნომერი</label>
                <input type="text" className="form-control" name="private_id" id="private_id" placeholder="11 ნიშნა პირადი ნომერი" onChange={ this.handleInputChange.bind(this)} value={formData.private_id} />
              </div>

              <p>
                <a className="btn btn-primary btn-lg" href="#" id="check_member_deposit" role="button" onClick={ ()=>{ this.checkMe() } }>შემოწმება</a>
              </p>


                <div id="resp_area">

                    { isLoaded && !error && <DisplayResponse member={member} /> }

                    { isLoaded && error && <div className="alert alert-danger" role="alert">შეცდომა! <br /> გთხოვთ მონაცემები შეიყვანოთ სრულად.</div> }

                </div>

            </div>
        )
    }

}



function DisplayResponse(props) {

    const { member } = props;

    if ( member.status == 1 ) {
        return <DisplayMember member={member} />;
    } else {
        return <DisplayError message={member} />;
    }


}

function DisplayMember(props) {

    const member = props.member;

    return (
        <div className="alert alert-info" role="alert">
            კლუბის წევრი: <strong>{ member.fullname }</strong><br />
            დავალიანება: <strong>{ member.deposit } ₾</strong><br />
            <ul>
                <li>ბოლო ჩარიცხვა: <strong>{ member.last_transaction_amount } ₾</strong></li>
                <li>ტრანზაქციის აღწერა: <strong>{ member.last_transaction_description }</strong></li>
                <li>ტრანზაქციის ასახვის დრო: <strong>{ member.last_transaction_date }</strong></li>
            </ul>
            <br />
            <em>
                მონაცემთა ბაზა ბოლოს განახლდა - <strong>{ member.last_update }</strong>,
                თუ თქვენი ტრანზაქცია ამ თარიღის შემდეგ გაკეთდა, ის ჩვენთან უახლოეს მომავალში აისახება.
            </em>
            <br />
            <br />
            შემოწირულობა შეგიძლიათ ჩარიცხოთ TBC ბანკის ანგარიშზე: <code>GE66TB1144536080100002</code><br />
            დანიშნულებაში მიუთითეთ "საწევრო შემოწირულობა" და თქენი პირადი ნომერი, ზუსტი იდენტიფიკაციისათვის<br />
        </div>
    );
}

function DisplayError(props) {

    const message = props.message;

    return <div className="alert alert-warning" role="alert">{message.msg}</div>;

}









export default Check;
