import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import {API_URL} from './_var';

import './../media/tamada.css';




class TamadaLoginScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
        }
    }

    handleInputChange(e){
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            ...this.state,
            [name]: value
        });
    }


    checkMe() {

        axios.get(API_URL+"tamada/auth", {
                params: this.state
            })
            .then( result => {

                if( result.data.status && result.data.token ){

                    // set Cookies
                    Cookies.set('token',result.data.token);

                    // refresh page
                    window.location.reload(false);

                }

            },
            error => {

            } )


    }


    render(){


        return(
            <div className="form-signin" role="form" >
              <h2 className="form-signin-heading">Let's Fill Sasmisebi</h2>
              <input type="username" name="username" className="form-control" placeholder="God Tamada" required autoFocus onChange={ this.handleInputChange.bind(this)} />
              <input type="password" name="password" className="form-control" placeholder="Password" required onChange={ this.handleInputChange.bind(this)} />
              <button className="btn btn-lg btn-primary btn-block" type="submit" onClick={ ()=>{ this.checkMe() } }>CHEERS</button>
            </div>
        )
    }
}




export default TamadaLoginScreen;
